<template>
  <div id="textract-result" class="container">
    <h1>Textract Results</h1>
    <Busy v-if="busy" primary text="Loading..." />
    <div v-else>
      <div class="d-flex justify-content-between mb-3">
        <h3 v-if="orgName">{{ orgName }}</h3>
        <b-button
          @click.stop="textract()"
          variant="outline-primary"
          class="mb-2"
          :disabled="textractBusy"
        >
          <font-awesome-icon :icon="['fas', 'alien-monster']" class="mr-1" />
          {{ textractResult ? "Restart" : "Start" }} Textract
        </b-button>
      </div>

      <!-- Shown when new textract is started -->
      <b-alert show v-if="textractMessage" variant="primary">
        {{ textractMessage }} Textract results will be available on this page. You can view
        running/queued jobs in
        <b-link :to="{ name: 'requested-jobs' }">Requested Jobs page</b-link>.
      </b-alert>

      <p v-if="!textractResult && !textractMessage">
        No textract jobs were run for this organisation.
      </p>
      <div v-else-if="textractResult">
        <p class="mb-4">
          Last textract was started at {{ formatDateTimeToMin(textractResult.created) }} by
          {{ textractResult.createdByUserEmail }}. {{ textractResult.completedJobs }} attachments
          out of {{ textractResult.startedJobs + textractResult.failedToStartJobs }} were analysed.
        </p>
        <b-tabs>
          <b-tab
            v-for="(numberStrings, type) in textractNumbers"
            :key="type"
            :title="`${type} numbers (${totalRows(type)})`"
            :active="type == 'Mobile'"
            @click="currentPage = 1"
          >
            <b-list-group>
              <b-list-group-item v-for="num in numberStrings" :key="num" v-b-toggle="num">
                <b-row no-gutters>
                  <!-- Number and copy button -->
                  <b-col cols="2" class="number-col">
                    <span class="number">{{ num }}</span>
                    <b-button
                      class="copy-btn d-none"
                      size="sm"
                      variant="light"
                      v-clipboard:copy="num"
                      v-clipboard:success="copySuccess"
                      @click.stop
                    >
                      <font-awesome-icon :icon="['far', 'clipboard']" class="text-muted mr-2" />
                      Copy
                    </b-button>
                  </b-col>

                  <!-- Number of results -->
                  <b-col cols="2" class="count-col">
                    <b-badge
                      v-if="textractResult.numbers[num].totalCount"
                      class="mx-1"
                      :variant="resultBadgeVariant(textractResult.numbers[num].totalCount)"
                    >
                      {{ textractResult.numbers[num].totalCount }}
                      {{ textractResult.numbers[num].totalCount == 1 ? "result" : "results" }}
                    </b-badge>
                  </b-col>

                  <!-- Invoice (supplier) names number has appeared in -->
                  <b-col class="supplier-col" cols="9">
                    <span
                      v-if="
                        textractResult.numbers[num].supplierNames &&
                        textractResult.numbers[num].supplierNames.length > 0
                      "
                    >
                      in
                      <b-badge
                        v-for="supplier in textractResult.numbers[num].supplierNames"
                        :key="supplier"
                        variant="light"
                      >
                        {{ supplier }}
                      </b-badge>
                    </span>
                  </b-col>

                  <!-- Expand section icon -->
                  <b-col cols="1" class="expand-col">
                    <font-awesome-icon :icon="['fas', 'chevron-up']" class="text-muted" />
                  </b-col>
                </b-row>

                <!-- List of invoice names + page location number has appeared in -->
                <b-collapse
                  :id="num"
                  @show="$set(showCollapse, num, true)"
                  @hidden="$set(showCollapse, num, false)"
                >
                  <div class="border mt-2 p-2 number-locations">
                    <ul v-if="showCollapse[num] == true" class="m-0">
                      <li
                        v-for="doc in textractResult.numbers[num].documents"
                        :key="doc.attachment"
                        class="py-1"
                      >
                        <b-link target="_blank" :href="doc.s3Url" @click.stop>
                          {{ doc.supplierName }}
                          <font-awesome-icon :icon="['fas', 'external-link']" class="mx-1" />
                        </b-link>
                        found in
                        <span
                          v-for="(loc, page) in textractResult.numbers[num].documentPageLocation[
                            doc.attachment
                          ]"
                          :key="loc + page"
                        >
                          <b-badge variant="secondary" class="mx-1">
                            page {{ page }} - {{ formatPageLocations(loc) }}
                          </b-badge>
                        </span>
                      </li>
                    </ul>
                  </div>
                </b-collapse>
              </b-list-group-item>
            </b-list-group>
            <div class="d-flex mt-2">
              <b-pagination
                v-if="totalRows(type) > perPage"
                class="reducer-pagination ml-auto"
                v-model="currentPage"
                :total-rows="totalRows(type)"
                :per-page="perPage"
              ></b-pagination>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";
import FormatHelper from "@/helper/formathelper";
import Busy from "@/components/Busy";

export default {
  name: "TextractResult",
  components: { Busy },
  data() {
    return {
      busy: true,
      textractBusy: false,
      textractResult: null,
      textractMessage: null,
      showCollapse: {},
      currentPage: 1,
      perPage: 100
    };
  },
  computed: {
    textractNumbers() {
      let numbers = {};
      if (this.textractResult) {
        if (this.textractResult.mobileNumbers && this.textractResult.mobileNumbers.length > 0) {
          numbers.Mobile = this.textractResult.mobileNumbers.slice(
            (this.currentPage - 1) * this.perPage,
            this.currentPage * this.perPage
          );
        }
        if (this.textractResult.landlineNumbers && this.textractResult.landlineNumbers.length > 0) {
          numbers.Landline = this.textractResult.landlineNumbers.slice(
            (this.currentPage - 1) * this.perPage,
            this.currentPage * this.perPage
          );
        }
      }
      return numbers;
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    formatCoboldCase: FormatHelper.formatCoboldCase,
    formatDateTimeToMin: FormatHelper.formatDateTimeToMin,
    orgShortName: FormatHelper.orgShortName,
    async fetchData() {
      this.busy = true;
      const client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}textracts/${this.$route.params.organisationId}`)
        .then((response) => {
          Console.log(response);
          this.orgName = response.data.orgName;
          this.textractResult = response.data.textractResult;
        })
        .catch((e) => {
          Console.error(e);
          this.showToast("There was an issue fetching textract data.", "warning");
        })
        .finally(() => (this.busy = false));
    },
    async textract() {
      this.textractBusy = true;
      const client = await ApiHelper.http();
      await client
        .get(
          `${ApiHelper.endPoint()}organisations/${
            this.$route.params.organisationId
          }/findAttachmentData`
        )
        .then((response) => {
          Console.log(response.data);
          if (response.data.message) {
            this.textractMessage = response.data.message;
          } else {
            this.textractMessage = "Textract started/queued successfully.";
          }
        })
        .catch((e) => {
          Console.error(e);
          this.showToast("Sorry, there was a problem starting Textract.", "warning");
        })
        .finally(() => (this.textractBusy = false));
    },
    resultBadgeVariant(totalCount) {
      if (totalCount > 20) {
        return "success";
      } else if (totalCount > 5) {
        return "primary";
      } else return "secondary";
    },
    formatPageLocations(loc) {
      if (loc) {
        return loc.join(", ").toLowerCase().replaceAll("_", " ");
      } else return "";
    },
    copySuccess() {
      this.showToast("Number copied successfully.", "success");
    },
    showToast(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    },
    totalRows(type) {
      return type == "Mobile"
        ? this.textractResult.mobileNumbers.length
        : this.textractResult.landlineNumbers.length;
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
#textract-result {
  @include badge-styles;
  .number-col {
    max-width: 7rem;
    white-space: nowrap;
    border-radius: 1rem;
    .copy-btn {
      max-height: 25px;
      width: 95%;
      padding-top: 0em;
    }
    &:hover {
      & > .number {
        display: none;
      }
      & > .copy-btn {
        display: block !important;
      }
    }
  }
  .count-col {
    max-width: 6.5rem;
    .badge {
      width: 5rem;
    }
  }
  .supplier-col {
    overflow: hidden;
    white-space: nowrap;
    .badge-light {
      color: $color-primary-blue !important ;
      background: white;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 8rem;
      margin-left: 0.5rem;
      margin-bottom: -0.5rem;
    }
  }
  .expand-col {
    position: absolute;
    right: 1rem;
    transform: rotate(180deg);
  }
  .list-group-item {
    background: $color-grey-lighter5 !important;
  }
  .number-locations {
    padding: 0.2rem !important;
    margin-top: 1rem !important;
    background: white;
    border-radius: 6px;
    cursor: default;
    .badge {
      margin-top: -10px !important;
    }
  }
}
</style>
