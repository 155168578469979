<template>
  <div id="pricing-hub">
    <vue-headful :title="title" />
    <b-container fluid class="px-5">
      <h1>Pricing Hub</h1>
      <h4>Upload Price Books</h4>
      <span class="small text-muted"
        >Supported suppliers: {{ supportedSuppliers.join(", ") }}.</span
      >
      <!-- TODO use a file upload component -->
      <div class="file-upload">
        <b-input-group prepend="Upload Files">
          <b-form-file
            multiple
            class="float-right"
            v-model="selectedFiles"
            accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ></b-form-file>
        </b-input-group>
        <div v-if="filesToUpload && filesToUpload.length > 0" class="mt-3">
          <p class="mb-2">Selected files:</p>
          <div class="my-1 py-2 px-3 file-row" v-for="file in filesToUpload" :key="file.name">
            <p class="m-0">
              {{ file.name }}
              <span v-if="file.name.includes('Base 365')" class="text-danger small"
                ><br />If possible, please upload separate Opus acquisition and renewal price
                books.</span
              >
              <b-button
                class="delete-button float-right d-inline-block p-0 px-2"
                variant="danger"
                @click="deleteRow(file)"
                >X</b-button
              >
            </p>
          </div>
          <b-button
            v-if="!busyUploading"
            variant="primary"
            class="float-right mt-2 mb-5"
            @click="uploadFiles()"
            :disabled="filesToUpload.length == 0"
            >Upload</b-button
          >
          <b-button v-else variant="primary" class="float-right mt-2 mb-5" disabled>
            <Busy :size="1.5" :margin="0" />
          </b-button>
        </div>
      </div>

      <h4 class="mt-4">Gas Pricing</h4>
      <b-form class="gas-pricing-form d-flex mb-4" @submit.prevent="priceGas">
        <b-form-input
          v-model="gasPricingForm.postcode"
          id="postcode-input"
          placeholder="Postcode"
          required
        />
        <b-form-input
          v-model="gasPricingForm.aq"
          id="gas-aq-input"
          placeholder="Annual Consumption"
          class="ml-3"
          type="number"
          required
        />
        <b-button type="submit" class="ml-3" variant="primary" :disabled="gasPricingBusy">
          <Busy v-if="gasPricingBusy" :size="1.5" :margin="0" class="px-2" />
          <span v-else>Price</span>
        </b-button>
      </b-form>
      <b-pagination
        v-if="gasPrices && gasPrices.length > perPage"
        v-model="gasTablePage"
        align="right"
        :total-rows="gasPrices.length"
        :per-page="perPage"
      />
      <b-table
        v-if="gasPrices != null"
        :items="gasPrices"
        :fields="gasPricesFields"
        :busy="gasPricingBusy"
        :per-page="perPage"
        :current-page="gasTablePage"
        show-empty
      />
      <b-pagination
        v-if="gasPrices && gasPrices.length > perPage"
        v-model="gasTablePage"
        align="right"
        :total-rows="gasPrices.length"
        :per-page="perPage"
      />

      <h4 class="mt-4">Electricity Pricing</h4>
      <b-form class="elec-pricing-form d-flex mb-4" @submit.prevent="priceElec">
        <b-form-input v-model="elecPricingForm.mpan" id="mpan-input" placeholder="MPAN" required />
        <b-form-input
          v-model="elecPricingForm.dayAq"
          id="elec-day-aq-input"
          placeholder="Day AQ"
          class="ml-3"
        />
        <b-form-input
          v-model="elecPricingForm.nightAq"
          id="elec-night-aq-input"
          placeholder="Night AQ"
          class="ml-3"
        />
        <b-form-input
          v-model="elecPricingForm.ewAq"
          id="elec-ew-aq-input"
          placeholder="Evening/Weekend AQ"
          class="ml-3"
        />
        <b-button type="submit" class="ml-3" variant="primary" :disabled="elecPricingBusy">
          <Busy v-if="elecPricingBusy" :size="1.5" :margin="0" class="px-2" />
          <span v-else>Price</span>
        </b-button>
      </b-form>
      <b-pagination
        v-if="elecPrices && elecPrices.length > perPage"
        v-model="elecTablePage"
        align="right"
        :total-rows="elecPrices.length"
        :per-page="perPage"
      />
      <b-table
        v-if="elecPrices != null"
        :items="elecPrices"
        :fields="elecPricesFields"
        :busy="elecPricingBusy"
        :per-page="perPage"
        :current-page="elecTablePage"
        show-empty
      />
      <b-pagination
        v-if="elecPrices && elecPrices.length > perPage"
        v-model="elecTablePage"
        align="right"
        :total-rows="elecPrices.length"
        :per-page="perPage"
      />
      <h4 class="mt-4">Remove Supplier Prices</h4>
      <div class="d-flex w-50">
        <b-select
          v-model="supplierToDelete"
          :options="suppliers"
          text-field="name"
          value-field="supplierId"
          :disabled="supplierSelectBusy"
        />
        <b-select class="ml-3" v-model="priceTypeToDelete" :options="priceTypeToDeleteOptions" />
        <b-button
          class="ml-3"
          variant="danger"
          :disabled="supplierSelectBusy || !supplierToDelete"
          @click="removeSupplier"
          ><Busy v-if="supplierSelectBusy" :size="1.5" :margin="0" class="px-3" />
          <span v-else>Remove</span></b-button
        >
      </div>
    </b-container>
  </div>
</template>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import Busy from "@/components/Busy";
import axios from "axios";
import { pricing } from "@/config/reducerproperties";

export default {
  name: "PricingHub",
  components: { Busy },
  data() {
    return {
      title: "Reducer - Pricing Hub",
      perPage: 25,

      busyUploading: false,
      selectedFiles: [],
      filesToUpload: [],

      supportedSuppliers: [
        "British Gas",
        "British Gas Lite",
        "SSE",
        "Total",
        "Scottish Power",
        "Opus",
        "SmartestEnergy",
        "Corona Energy",
        "EDF"
      ],
      suppliers: [],
      supplierToDelete: null,
      supplierSelectBusy: false,
      priceTypeToDelete: null,
      priceTypeToDeleteOptions: [
        { value: null, text: "Electricity and Gas" },
        { value: "elec", text: "Electricity only" },
        { value: "gas", text: "Gas only" }
      ],

      gasPricingBusy: false,
      gasPricingForm: {
        exitZone: null,
        aq: null
      },
      gasPrices: null,
      gasTablePage: 1,
      gasPricesFields: [
        {
          key: "supplierName",
          label: "Supplier"
        },
        {
          key: "exitZone"
        },
        {
          key: "contractStart",
          formatter: (v) => new Date(v).toLocaleDateString("en-US")
        },
        {
          key: "contractEnd",
          formatter: (v) => new Date(v).toLocaleDateString("en-US")
        },
        {
          key: "contractTerm"
        },
        {
          key: "minConsumption"
        },
        {
          key: "maxConsumption"
        },
        {
          key: "salesType"
        },
        {
          key: "standingCharge"
        },
        {
          key: "unitRate"
        }
      ],

      elecPricingBusy: false,
      elecPricingForm: {
        mpan: null,
        dayAq: null,
        nightAq: null,
        ewAq: null
      },
      elecPrices: null,
      elecTablePage: 1,
      elecPricesFields: [
        {
          key: "supplierName",
          label: "Supplier"
        },
        {
          key: "supplyZone",
          label: "PES"
        },
        {
          key: "profileCode",
          label: "Profile"
        },
        {
          key: "contractStart",
          formatter: (v) => new Date(v).toLocaleDateString("en-US")
        },
        {
          key: "contractEnd",
          formatter: (v) => new Date(v).toLocaleDateString("en-US")
        },
        {
          key: "contractTerm"
        },
        {
          key: "minConsumption"
        },
        {
          key: "maxConsumption"
        },
        {
          key: "salesType"
        },
        {
          key: "meterType"
        },
        {
          key: "standingCharge"
        },
        {
          key: "unitRate"
        },
        {
          key: "dayUnitRate"
        },
        {
          key: "nightUnitRate"
        }
      ]
    };
  },
  created() {
    this.fetchSuppliers();
  },
  watch: {
    selectedFiles: function (value) {
      //add to already existing fileslist and prevent duplicates
      if (value && value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          if (!this.filesToUpload.some((elem) => elem.name == value[i].name)) {
            this.filesToUpload.push(value[i]);
          }
        }
      }
    }
  },
  methods: {
    async fetchSuppliers() {
      this.supplierSelectBusy = true;
      const client = await ApiHelper.http();
      return client
        .get(`${ApiHelper.endPoint()}suppliers?summarised=true`)
        .then((response) => {
          console.log("Suppliers response :", response);
          this.suppliers = Object.values(response.data.suppliers).filter(
            (s) =>
              this.supportedSuppliers.includes(s.name) &&
              s.categories &&
              (s.categories.includes("ELECTRICITY") || s.categories.includes("GAS"))
          );
        })
        .catch((e) => {
          this.showMessage("There was a problem getting supplier data", "warning");
          console.error(e);
        })
        .finally(() => (this.supplierSelectBusy = false));
    },

    async priceGas() {
      this.gasPricingBusy = true;
      const API_ENDPOINT = pricing.baseUrl + "price-gas";
      axios.defaults.headers.common = {};

      await axios
        .post(
          API_ENDPOINT,
          JSON.stringify({
            postcode: this.gasPricingForm.postcode,
            aq: this.gasPricingForm.aq
          })
        )
        .then((response) => {
          console.log(response);
          this.gasPrices = JSON.parse(response.data.body);
        })
        .catch((e) => {
          this.gasPrices = null;
          this.showMessage("Couldn't get gas prices.", "warning");
          console.log(e);
        })
        .finally(() => (this.gasPricingBusy = false));
    },

    async priceElec() {
      this.elecPricingBusy = true;
      const API_ENDPOINT = pricing.baseUrl + "price-elec";
      axios.defaults.headers.common = {};

      await axios
        .post(API_ENDPOINT, JSON.stringify(this.elecPricingForm))
        .then((response) => {
          console.log(response);
          this.elecPrices = JSON.parse(response.data.body);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => (this.elecPricingBusy = false));
    },

    async uploadFiles() {
      let formData = new FormData();
      for (let i = 0; i < this.filesToUpload.length; i++) {
        const blob = new Blob([this.filesToUpload[i]], {
          size: this.filesToUpload[i].size,
          type: this.filesToUpload[i].type
        });
        formData.append("file", this.filesToUpload[i]);
      }

      this.busyUploading = true;
      const client = await ApiHelper.http();
      await client
        .post(`${ApiHelper.endPoint()}pricing/files`, formData)
        .then(() => {
          this.filesToUpload = [];
          this.selectedFiles = [];
          this.showMessage("File(s) uploaded successfully.", "success");
        })
        .catch((e) => {
          console.log(e.response);
          this.showMessage("There was a problem saving the file(s).", "warning");
        })
        .finally(() => (this.busyUploading = false));
    },

    removeSupplier() {
      const API_ENDPOINT = pricing.baseUrl + "delete-supplier";
      axios.defaults.headers.common = {};

      axios.post(
        API_ENDPOINT,
        JSON.stringify({
          supplierId: this.supplierToDelete,
          priceType: this.priceTypeToDelete
        })
      );
      this.showMessage(
        "Removing prices in the background. An email with a status update will be sent shortly.",
        "success"
      );
      this.supplierToDelete = null;
    },

    deleteRow(file) {
      this.filesToUpload.splice(this.filesToUpload.indexOf(file), 1);
      this.selectedFiles.splice(this.selectedFiles.indexOf(file), 1);
    },

    showMessage(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
#pricing-hub {
  .file-row {
    background-color: #f0f3f5;
    border-radius: 10px;

    &:hover {
      background-color: #d4d7da;
    }

    .delete-button {
      background-color: transparent;
      border-color: transparent;
      color: grey;
      font-weight: bolder;
    }
  }
}
</style>
